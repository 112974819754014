<template>
    <!-- 农场应用续费 -->
    <div class="cpr-cont">
        <div class="cpr-form" v-if="shop && !order_number">
            <img class="logo" :src="shop.program.logo" alt="">
            <div class="name">{{ shop.program.program_name }}</div>

            <div class="cpr-form-item">
                <div class="label">{{cutomTxt}}版本：</div>
                <div class="value">{{ shop.server.server_name }}</div>
            </div>
            <div class="cpr-form-item">
                <div class="label">版本描述：</div>
                <div class="value">{{ shop.server.description }}</div>
            </div>
            <div class="cpr-form-item">
                <div class="label">{{cutomTxt}}价格：</div>
                <div class="value"><span class="price" v-if="shop.order">{{ shop.order.actual}}</span>   元</div>
            </div>
            <div class="cpr-form-item">
                <div class="label">{{cutomTxt}}天数：</div>
                <div class="value">{{ shop.server.expire }}天</div>
            </div>
            <div class="cpr-form-item" v-if="renewType == 'buy'">
                <div class="label">续费价格：</div>
                <div class="value">{{ shop.server.renewal_discount_price}}  元 /{{ shop.server.expire }}天</div>
            </div>
            <div class="cpr-form-item">
                <div class="label">支付方式：</div>
                <div class="cpr-form-method value">
                    <div class="method" :class="pay_method == 2 ?'active':''" @click="pay_method = 2">
                        <img v-if="pay_method == 2" class="method-active" src="@/assets/img/icon-share-selected.png" alt="" />
                        <i class="ri-alipay-line ri-xl ri-top" style="color: #2196F3;"></i>
                        支付宝支付
                    </div>
                    <div class="method" :class="pay_method == 1 ?'active':''" @click="pay_method = 1">
                        <img v-if="pay_method == 1" class="method-active" src="@/assets/img/icon-share-selected.png" alt="" />
                        <i class="ri-wechat-pay-line ri-xl ri-top" style="color: #33CC66;"></i>
                        微信支付
                    </div>
                </div>
            </div>
            <div class="cpr-form-item">
                <div class="label"></div>
                <div class="value">
                    <a-space>
                        <a-button type="primary" @click="createRenewOrder">立即{{cutomTxt}}</a-button>
                        <a-button @click="$router.go(-1)">返回</a-button>
                    </a-space>
                </div>
            </div>

        </div>

        <!-- 微信支付 -->
        <div v-if="show.wxpay && shop">
            <com-wxpay-modal 
                :qrcode="show.wx_pay_qrcode" 
                :money="shop.order.actual"
                @paied='checkPayd'
                @close="show.wxpay = false"
            >
            </com-wxpay-modal>
        </div>

        <!-- 支付成功提示 -->
        <div class="cpr-success" v-if="order_number">
            <div class="cpr-success-icon">
                <div class="icon"><i class="ri-check-line"></i></div>
                <span>支付成功</span> 
            </div>
            <p class="cpr-tips">您的订单 {{order_number}} 已支付成功，<span>{{time}}</span> 秒后将自动跳转~</p>
            <a-button type="primary" @click="successJump(true)">立即跳转</a-button>
        </div>
    </div>
</template>
<script>
import comWxpayModal from '@/components/pay/com-wxpay-modal.vue'
import { computed, reactive, toRefs, watch } from 'vue'
import appModel from '@/api/saas/app.js'
import router from '@/router'
export default {
    name:"com-program-renew",
    components:{
        comWxpayModal
    },
    props:{
        programId:{
            type:[Number,String]
        },
        jumpPath:{
            type:String,
            default:"/shop/index"
        },
        tradeNo:{
            type:String
        },
        renewType:{     //类型 renew续费 buy购买
            type:String,
            default:"renew" 
        }
    },
    setup(props) {
        const state = reactive({
            shop:null,  //店铺信息
            pay_method:2,
            order:null, //创建的订单信息
            show:{
                wxpay:false,
                wx_pay_qrcode:''
            },
            order_number:"",    //支付成功得到order_number并展示支付成功提示
            time:5,
            timer:null
        })

        watch(()=>props.tradeNo,(val)=>{
            state.order_number = val
            if( val ){
                successJump(false)
            }
        },{deep:true,immediate:true})

        appModel.getShopPreOrder({
            program_id:props.programId,
            order_type:'program_order',
            is_renew:true,
            is_saas:true
        },res=>{
            state.shop = res
        })


        const cutomTxt = computed(()=>{
            if( props.renewType == 'renew' ) {
                return '续费'
            }
            if( props.renewType == 'buy' ) {
                return '购买'
            }
        })

        //创建续费订单
        function createRenewOrder(){
            appModel.createShopOrder(props.programId,state.pay_method,"program_order",res=>{
                state.order = res
                appModel.shopOrderPay(res.order_id,'program_order',state.pay_method,res=>{
                    state.show.wxpay = true;
					state.show.wx_pay_qrcode = res.qr_code;
                })
            })
        }

        //检测是否支付
        function checkPayd(){
            appModel.checkIsPay(state.order.order_number,()=>{
                state.order_number = state.order.order_number;
                state.show.wxpay = false;
            })
        }

        function successJump(isNow) {
            if( isNow ){
                clearInterval(state.timer)
                router.push( props.jumpPath)
            }else{
                state.timer = setInterval(() => {
                    if( state.time >0 ){
                        state.time --
                    }else{
                        clearInterval(state.timer)
                        router.push( props.jumpPath)
                    }
                }, 1000);
            }
		}

        return{
            ...toRefs(state),
            createRenewOrder,
            checkPayd,
            successJump,
            cutomTxt
        }
        
    },
}
</script>
<style scoped lang="scss">
.cpr-cont{
    background: #fff;
}
.cpr-form{
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 24px 0;

    .logo{
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .name{
        font-weight: bold;
        font-size: 16px;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    &-item{
        width: 60%;
        margin-left: 25%;
        margin-top: 12px;
        display: flex;
        align-items: center;
        min-height: 40px;
        color: #666;
        .label{
            width: 30%;
            text-align: right;
        }
        .value{
            width: 70%;
            font-size: 16px;
            text-align: left;
        }
        .price{
            color: #FF0033;
            font-weight: bold;
            font-size: 18px;
        }
    }

    &-method{
        display: flex;

        .method{
            width: 160px;
            height: 56px;
            border: 1px solid #ccc;
            line-height: 56px;
            text-align: center;
            margin-right: 24px;
            cursor: pointer;
            position: relative;

            &-active{
                position: absolute;
                width: 24px;
                right: 0;
                bottom: 0;
            }
        }
        .active{
            border: 2px solid #ff4544;
        }
    }
}

.cpr-success{
    width: 100%;
    text-align: center;
    padding-top: 48px;

    &-icon{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #00CC66;
            color: #fff;
            line-height: 40px;
            text-align: center;

            i{
                font-size: 24px;
            }
        }
        span{
            margin-left: 12px;
            font-size: 18px;
            color: #000;
        }
    }

    .cpr-tips{
        margin: 24px 0;
        color: #666;
    }
}
</style>